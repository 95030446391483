import {SECRET_CHAIN_ID, SECRET_REST_URL, SNIP721_CONTRACT_ADDRESS} from './config'
import { SigningCosmWasmClient } from 'secretjs'


const customFees = {
    exec: {
        gas: "140000",
    },
}

export const getSignature = async (address) => {
    const permitName = "View MetaRats";
    const permissions = ["owner"];
    const { signature } = await window.keplr.signAmino(
        SECRET_CHAIN_ID,
        address,
        {
            chain_id: SECRET_CHAIN_ID,
            account_number: "0", // Must be 0
            sequence: "0", // Must be 0
            fee: {
                amount: [{denom: "uscrt", amount: "0"}], // Must be 0 uscrt
                gas: "1", // Must be 1
            },
            msgs: [
                {
                    type: "query_permit", // Must be "query_permit"
                    value: {
                        permit_name: permitName,
                        allowed_tokens: [SNIP721_CONTRACT_ADDRESS],
                        permissions: permissions,
                    },
                },
            ],
            memo: "", // Must be empty
        },
        {
            preferNoSetFee: true, // Fee must be 0, so hide it from the user
            preferNoSetMemo: true, // Memo must be empty, so hide it from the user
        }
    );
    const signature_data = JSON.stringify(signature)
    localStorage.setItem('signature', signature_data);
    return signature
}


export const connectKeplr = async (dispatch) => {
    try {

        // Get Keplr account details
        dispatch({type: 'set-loading', payload: true})
        const client = await getClient(dispatch)
        const account = await client.getAccount()

        if(localStorage.getItem('address') !== account.address) {
            await getSignature(account.address)
        }

        localStorage.setItem('connected', 'true');
        localStorage.setItem('address', account.address)
        dispatch({type: 'set-connected', payload: true})
        dispatch({type: 'set-loading', payload: false})
        return true
    } catch (e) {
        dispatch({type: 'set-loading', payload: false})
        console.log(e.message)
        return false
    }

}

export const getClient = async () => {
    try {
        await window.keplr.enable(SECRET_CHAIN_ID);

        const offlineSigner = window.getOfflineSigner(SECRET_CHAIN_ID);

        const accounts = await offlineSigner.getAccounts();
        const tmp_address = accounts[0].address

        const cosmJS = new SigningCosmWasmClient(
            SECRET_REST_URL,
            tmp_address,
            offlineSigner,
            window.getEnigmaUtils(SECRET_CHAIN_ID), customFees
        );
        return await cosmJS;
    } catch (e){
        console.log(e.message)
    }
}