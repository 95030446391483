import styled from "styled-components";
import Divider from '@mui/material/Divider';
import {Box, createTheme, Fab, Grid} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {ThemeProvider} from "@emotion/react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../../viewrat.css'
import rarity from '../../data/trait_rarity.json'
import ranks from '../../data/rats_rank.json'
import rats from '../../data/output.json'
import {useState} from "react";
import public_img_data from "../../data/public_img_data.json"

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#ffc106"
        },
        action: {
            disabledBackground: 'rgba(255, 255, 255, 0.3)',
            disabled: '#072534'
        }
    },
});

const SectionContainer = styled.div`
  letter-spacing: 0px;
  background: linear-gradient(130deg, var(--yellow), var(--yellow-alt));
  box-shadow: -5px 10px 10px rgb(6, 35, 51, 0.2);
  .textSection {
    width: 65%;
    max-width: 500px;
    margin-left: 50px;
    h1 {
      font-weight: 500;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 26px;
      margin-top: 0;
    }
    img {
      max-width: 100%;
    }
    button {
      width: 200px;
      height: 50px;
      border: 1px solid var(--dark-blue);
      position: relative;
      color: var(--dark-blue);
      letter-spacing: 2px;
      margin: 0 auto;
      text-transform: uppercase;
      font-family: var(--font-family-heading);
      font-size: 20px;
      font-weight: 400;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: transparent;
      margin-bottom: 20px;
      margin-top: -20px;
      ::after {
        content: "Reload Collection";
        transition: opacity 0.25s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        color: var(--yellow-alt);
        background: linear-gradient(130deg, var(--light-blue), var(--dark-blue));
        opacity: 0;
        pointer-events: none;
      }
      :hover {
        ::after {
          opacity: 1;
        }
      }
`;

const ViewRat = (props) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let rank_data = ranks.filter(obj => {
        return obj.id === props.ratID
    })
    let rank = rank_data[0].rank
    let rat_data = rats.list[props.ratID - 1]

    const enableLoading = () => {
        setTimeout(() => {
            props.setRatID('')
            props.setShowLoader(false);
        }, 2000);
    }

    const handleBack = () => {
        const index = props.state.tokens.indexOf(props.ratID.toString())
        if(index !== 0) {
            props.setRatID(props.state.tokens[index - 1])
        }
    }

    const handleForward = () => {
        const index = props.state.tokens.indexOf(props.ratID.toString())
        if(index !== props.state.tokens.length - 1) {
            props.setRatID(props.state.tokens[index + 1])
        }
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Box style={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: 0}} className="MainBox">
                <Fab color="primary" disabled={props.state.tokens.indexOf(props.ratID.toString()) === 0} size="small" aria-label="back" style={{marginRight: 20}} onClick={handleBack}>
                    <ArrowBackIcon styte={{color: "#031218" }} />
                </Fab>
                <SectionContainer>
                    <CancelIcon fontSize="medium"
                                style={{float: 'right', marginRight: 15, marginTop: 15, cursor: 'pointer'}}
                                onClick={() => {
                                    props.setShowLoader(true)
                                    enableLoading()
                                }}
                    />
                    <Box style={{display: 'flex', justifyContent: 'center', marginLeft: 15}}>
                        <h1 style={{marginBottom: 0}}>MetaRat #{props.ratID}</h1>
                    </Box>
                    <Box style={{display: 'flex', justifyContent: 'center', marginLeft: 15}}>
                        <h3 style={{marginTop: 5}}>Rank: {rank}</h3>
                    </Box>
                    <Divider style={{backgroundColor: "#54656e"}} />
                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 20}}>
                        <Box style={{width: 400, height: 400}}>
                            <img
                                src={public_img_data[props.ratID - 1].public_img_url}
                                style={{width: 400, height: 400}}
                                loading="lazy"
                            />
                        </Box>
                        <TableContainer component={Paper} style={{marginLeft: 10, width: 600}}>
                            <Table aria-label="simple table" size="small" stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Attribute Type</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Rarity %</TableCell>
                                        <TableCell align="right">Rarity Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{tableLayout: "auto"}}>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Background
                                        </TableCell>
                                        <TableCell>{rat_data.background}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.background[rat_data.background].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.background[rat_data.background].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Fur Color
                                        </TableCell>
                                        <TableCell>{rat_data.fur}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.fur[rat_data.fur].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.fur[rat_data.fur].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Tattoo
                                        </TableCell>
                                        <TableCell>{rat_data.tattoo}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.tattoo[rat_data.tattoo].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.tattoo[rat_data.tattoo].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Ear & Hand Color
                                        </TableCell>
                                        <TableCell>{rat_data.ear_hands}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.ear_hands[rat_data.ear_hands].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.ear_hands[rat_data.ear_hands].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Torso
                                        </TableCell>
                                        <TableCell>{rat_data.torso}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.torso[rat_data.torso].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.torso[rat_data.torso].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Handheld
                                        </TableCell>
                                        <TableCell>{rat_data.handheld}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.handheld[rat_data.handheld].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.handheld[rat_data.handheld].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Mouth Addition
                                        </TableCell>
                                        <TableCell>{rat_data.mouth_addon}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.mouth_addon[rat_data.mouth_addon].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.mouth_addon[rat_data.mouth_addon].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Mouth
                                        </TableCell>
                                        <TableCell>{rat_data.mouth}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.mouth[rat_data.mouth].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.mouth[rat_data.mouth].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Scar
                                        </TableCell>
                                        <TableCell>{rat_data.scars}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.scars[rat_data.scars].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.scars[rat_data.scars].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Hat
                                        </TableCell>
                                        <TableCell>{rat_data.hat}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.hat[rat_data.hat].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.hat[rat_data.hat].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Eyes
                                        </TableCell>
                                        <TableCell>{rat_data.eyes}</TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.round(((rarity.eyes[rat_data.eyes].trait_rarity * 100) + Number.EPSILON) * 100) / 100
                                            }%
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                Math.ceil(rarity.eyes[rat_data.eyes].trait_rarity_score * 100) / 100
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </SectionContainer>
                <Fab size="small" color="primary" disabled={props.state.tokens.indexOf(props.ratID.toString()) === props.state.tokens.length - 1} aria-label="forward" style={{marginLeft: 20}} onClick={handleForward}>
                    <ArrowForwardIcon styte={{color: "#031218" }} />
                </Fab>
            </Box>
        </ThemeProvider>
    );
};

export default ViewRat;
