import styled from "styled-components";
import hero from "../../assets/HeroImage3-min.png";
import logo from "../../assets/cigarclub_text.png";
import { Flex } from "../Generic";
import {Alert, Snackbar, TextField, createTheme, Grid, CircularProgress, Box} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {ThemeProvider} from "@emotion/react";
import ratimage from "../../assets/rat-min2.png";
import {SECRET_CHAIN_ID, SNIP721_CONTRACT_ADDRESS} from "../../keplr/config";
import {connectKeplr, getClient, getSignature} from "../../keplr/keplr_func";
import {useState} from "react";
import axios from "axios";

const theme = createTheme({
    palette: {
        primary: {
            main: "#000"
        }
    },
});

const SectionContainer = styled.div`
  max-width: 100%;
  transform: translateZ(60px);
  letter-spacing: 0px;
  background: linear-gradient(130deg, var(--yellow), var(--yellow-alt));
  box-shadow: -5px 10px 10px rgb(6, 35, 51, 0.2);
  padding: 20px 30px;
  .sideImg {
    margin-top: auto;
    position: relative;
    top: 20px;
    width: 40%;
  }
  .textSection {
    width: 60%;
    max-width: 500px;
    margin-left: 50px;
    h1 {
      font-weight: 500;
    }
    p {
      font-weight: 500;
      font-size: 26px;
      margin-top: 0;
    }
    img {
      max-width: 100%;
    }
    button {
      width: 200px;
      height: 50px;
      border: 1px solid var(--dark-blue);
      color: var(--dark-blue);
      position: relative;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: var(--font-family-heading);
      font-size: 20px;
      font-weight: 400;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: transparent;
      ::after {
        content: "JOIN THE CLUB";
        transition: opacity 0.25s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        color: var(--yellow-alt);
        background: linear-gradient(130deg, var(--light-blue), var(--dark-blue));
        opacity: 0;
        pointer-events: none;
      }
      :hover {
        ::after {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    & > div {
      flex-direction: column;
    }

    .textSection,
    .sideImg {
      width: 100%;
      margin: 0;
    }
  }
`;

const grabRats = async (dispatch) => {
    try {
        // Grab client for testnet connection and start loading icon
        await connectKeplr(dispatch)
        dispatch({type: 'set-loading', payload: true})
        const client = await getClient()
        const account = await client.getAccount()
        const address = account.address
        const permitName = "View MetaRats";
        const permissions = ["owner"]
        if (localStorage.getItem('signature') === null) {
            await getSignature(address)
            console.log(address)
            localStorage.setItem('address', address)
        }
        const token_info = await client.queryContractSmart(
            SNIP721_CONTRACT_ADDRESS,
            {
                with_permit: {
                    query: { tokens: {
                            owner: address,
                            limit: 150
                        } },
                    permit: {
                        params: {
                            permit_name: permitName,
                            allowed_tokens: [SNIP721_CONTRACT_ADDRESS],
                            chain_id: SECRET_CHAIN_ID,
                            permissions: permissions,
                        },
                        signature: JSON.parse(localStorage.getItem('signature')),
                    },
                },
            }
        )
        let tokens = token_info.token_list.tokens
        console.log(tokens)
        return tokens
    } catch (e) {
        console.log(e.message)
    }
    return []
}

const Container = styled.div`
  position: relative;

  .dimmer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0f0f0f;
    opacity: 0.65;
    z-index: 1;
  }
  #cards {
    gap: 20px;
  }
  #hero-content {
    position: relative;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > img {
      width: 700px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1200px) {
    #cards {
      flex-direction: column;
      gap: 20px;
    }
    
    .logoImg {
      min-width: 100%;
    }
    
    

    #hero-content {
      padding-bottom: 50px;
      & > img {
        width: 200px;
        margin-bottom: 10px;
        margin-top: 140px;
      }
    }
  }
  @media (max-width: 4000px) {
    .logoImg {
      margin-top: -15%;
      min-width: 50%;
    }
  }
  @media (max-width: 2000px) {
    .logoImg {
      margin-top: 0;
      min-width: 50%;
    }
    @media (max-width: 1200px) {
      .logoImg {
        margin-top: 0;
        min-width: 75%;
      }
  }
`;

const HeroImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const updateDiscord = async (keys, dispatch, discord) => {
    let success = false
    let count = 0;
    if(keys.length > 5){
        count = 5;
    } else {
        count = keys.length
    }
    for(let i = 0; i < count; i++){
        const response = await axios.post("https://metarats.io/api/add_discord", {
            rat_id: keys[i].id,
            key: keys[i].key,
            discord_id: discord
        })
        if(response.data === "Discord updated successfully!") {
            success = true
        } else if (response.data === "Discord ID is not correct or you have not joined the server!") {
            dispatch({type: 'set-alert-message', payload: 'Your Discord username is incorrect or you have not joined the server!'})
            dispatch({type: 'set-alert-type', payload: 'error'})
            dispatch({type: 'set-open-alert', payload: true})
            dispatch({type: 'set-loading', payload: false})
        }
    }
    if(success){
        dispatch({type: 'set-alert-message', payload: 'Discord updated successfully!'})
        dispatch({type: 'set-alert-type', payload: 'success'})
        dispatch({type: 'set-open-alert', payload: true})
        dispatch({type: 'set-loading', payload: false})
    }

}

const grabKeys = async (dispatch, setKeys, discord) => {
    if(discord.length < 6) {
        dispatch({type: 'set-alert-message', payload: 'Your Discord username is too short!'})
        dispatch({type: 'set-alert-type', payload: 'error'})
        dispatch({type: 'set-open-alert', payload: true})
        dispatch({type: 'set-loading', payload: false})
        return
    }
    if(!discord.includes("#")){
        dispatch({type: 'set-alert-message', payload: 'Incorrect username format; Example#1234'})
        dispatch({type: 'set-alert-type', payload: 'error'})
        dispatch({type: 'set-open-alert', payload: true})
        dispatch({type: 'set-loading', payload: false})
        return
    }
    let tokens = await grabRats(dispatch)
    try {
        // Grab client for testnet connection and start loading icon
        if(tokens.length > 0){
            const keys = []
            const client = await getClient()
            const account = await client.getAccount()
            const address = account.address
            const permitName = "View MetaRats";
            const permissions = ["owner"]
            if (localStorage.getItem('signature') === null || localStorage.getItem('address') !== address) {
                await getSignature(address)
                localStorage.setItem('address', address)
            }
            if(tokens.length > 5){
                tokens = tokens.slice(0,5)
            }
            for(let i = 0; i < tokens.length; i++){
                console.log(tokens[i])
                const token_info = await client.queryContractSmart(
                    SNIP721_CONTRACT_ADDRESS,
                    {
                        with_permit: {
                            query: { private_metadata: {
                                    token_id: tokens[i]
                                } },
                            permit: {
                                params: {
                                    permit_name: permitName,
                                    allowed_tokens: [SNIP721_CONTRACT_ADDRESS],
                                    chain_id: SECRET_CHAIN_ID,
                                    permissions: permissions,
                                },
                                signature: JSON.parse(localStorage.getItem('signature')),
                            },
                        },
                    }
                )
                keys.push({
                    id: tokens[i],
                    key: token_info.private_metadata.extension.priv_key
                })
            }
            setKeys(keys)
            await updateDiscord(keys, dispatch, discord)
        } else {
            dispatch({type: 'set-alert-message', payload: 'Unable to verify your ownership. Please contact support.'})
            dispatch({type: 'set-alert-type', payload: 'error'})
            dispatch({type: 'set-open-alert', payload: true})
            dispatch({type: 'set-loading', payload: false})
        }
    } catch (e) {
        dispatch({type: 'set-alert-message', payload: 'Unable to verify your ownership. Please contact support.'})
        dispatch({type: 'set-alert-type', payload: 'error'})
        dispatch({type: 'set-open-alert', payload: true})
        dispatch({type: 'set-loading', payload: false})
        console.log(e.message)
    }
}

const LinkDiscord = (props) => {
    const [discord, setDiscord] = useState('')
    const [keys, setKeys] = useState([])
  return (
    <Container>
        <Snackbar open={props.state.openAlert}
                  anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                  }}
        >
            <Alert
                severity={props.state.alertType}
                style={{backgroundColor: "#011117", color: "#fffcfa", border: "1px solid #0a4861"}}
                action={
                    <IconButton
                        aria-label="close"
                        size="small"
                        color="inherit"
                        onClick={() => {
                            props.dispatch({type: 'set-open-alert', payload: false})
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {props.state.alertMessage.includes("refer") ? <a> {props.state.alertMessage} <a style={{color: "#fff"}} href="https://t.co/qiHZfKIs6F" target="_blank">trouble-shooting guide</a></a> : <a>{props.state.alertMessage}</a> }
            </Alert>
        </Snackbar>
      <Flex id="hero-content">
        <img src={logo} alt="MetaRats" className="logoImg" />
            <Grid item xs={10} sm={10} md={5.5}>
                <SectionContainer style={{marginBottom: 100}}>
                    <Flex flexDirection="row" justifyContent="flex-end" alignItems="center">
                        <Flex flexDirection="column" className="textSection">
                            <h1>Join the Cigar Club</h1>
                            <ThemeProvider theme={theme}>
                                <TextField placeholder="Example#1234" autoFocus={true} id="outlined-basic" label="Discord Username"
                                           variant="outlined" value={discord} color="primary" style={{width: "90%", marginBottom: 10}}
                                           onChange={(e) => setDiscord(e.target.value)}
                                />
                            </ThemeProvider>
                            <Box style={{display: 'flex', justifyContent: 'center', width: '90%'}}>
                                {props.state.loading ?
                                    <CircularProgress color="success" style={{marginBottom: 10, marginTop: 10}} />
                                    :
                                    <button onClick={() => { grabKeys(props.dispatch, setKeys, discord) }}>JOIN THE CLUB</button>
                                }

                            </Box>
                        </Flex>
                        <img src={ratimage} alt="MetaRats" loading="lazy" className="sideImg" />
                    </Flex>
                </SectionContainer>
            </Grid>
        </Flex>
      <div className="dimmer" />
      <HeroImg loading="eager" src={hero} />
    </Container>
  );
};

export default LinkDiscord;
