import mycollection from "../../assets/mycollection.png";
import { useEffect, useState } from "react";
import CollectionItems from "../CollectionItems";
import ViewRat from "../ViewRat";
import {connectKeplr, getClient, getSignature} from "../../keplr/keplr_func";
import {SECRET_CHAIN_ID, SNIP721_CONTRACT_ADDRESS} from "../../keplr/config";

const grabRats = async (dispatch) => {
    try {
        // Grab client for testnet connection and start loading icon
        await connectKeplr(dispatch)
        dispatch({type: 'set-loading', payload: true})
        const client = await getClient()
        const account = await client.getAccount()
        const address = account.address
        const permitName = "View MetaRats";
        const permissions = ["owner"]
        if (localStorage.getItem('signature') === null) {
            await getSignature(address)
            console.log(address)
            localStorage.setItem('address', address)
        }
        const token_info = await client.queryContractSmart(
            SNIP721_CONTRACT_ADDRESS,
            {
                with_permit: {
                    query: { tokens: {
                            owner: address,
                            limit: 150
                        } },
                    permit: {
                        params: {
                            permit_name: permitName,
                            allowed_tokens: [SNIP721_CONTRACT_ADDRESS],
                            chain_id: SECRET_CHAIN_ID,
                            permissions: permissions,
                        },
                        signature: JSON.parse(localStorage.getItem('signature')),
                    },
                },
            }
        )
        let tokens = token_info.token_list.tokens
        console.log(tokens)
        dispatch({type: 'set-loading', payload: false})
        return tokens
    } catch (e) {
        dispatch({type: 'set-loading', payload: false})
    }
    return []
}


const CollectionList = (props) => {
    const [ratID, setRatID] = useState('')
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(async () => {
        props.dispatch({type: 'set-tokens', payload: (await grabRats(props.dispatch))})
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 820;

    if(isMobile) {
        window.location = "/";
    }
    if(ratID === ''){
        return (
            <main style={{minHeight: "100vh", minWidth: "100vw"}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                        <img src={mycollection} alt="MetaRats" style={{marginTop: "6%"}} />
                    <CollectionItems state={props.state} setRatID={setRatID} setShowLoader={props.setShowLoader} grabRats={grabRats} dispatch={props.dispatch} />
                </div>
            </main>
        );
    }
    return (
            <main style={{minHeight: "100vh", minWidth: "100vw"}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <ViewRat ratID={ratID} setRatID={setRatID} state={props.state} dispatch={props.dispatch} setShowLoader={props.setShowLoader} />
                </div>
            </main>
    );
};

export default CollectionList;
