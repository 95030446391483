import styled from "styled-components";
import hero from "../../assets/HeroImage3-min-card.jpg";
import { ReactComponent as Check } from "../../assets/check.svg";

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: linear-gradient(130deg, var(--primary), var(--secondary));
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  transform: ${(props) => (props.right ? "translateX(calc(50% + 45px))" : "translateX(calc(-50% - 45px))")};
  & > * {
    z-index: 1;
  }
  h1 {
    margin: 5px 0 15px;
    font-size: 24px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  p {
    margin: 0;
  }

  .date {
    text-transform: uppercase;
    font-size: 14px;
  }

  .body {
    font-size: 16px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    filter: brightness(0.2);
    transition: 0.25s ease;
    object-fit: cover;
  }

  .check {
    fill: var(--neon);
    width: 25px;
    height: auto;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  :hover {
    .stick::after {
      box-shadow: 0 0 10px rgba(31, 255, 124, 0.7);
    }
    img {
      filter: brightness(0.3);
    }
  }

  @media (max-width: 768px) {
    transform: none;
    left: 0;
    position: relative;
  }

  @media (min-width: 769px) and (max-width: 990px) {
    max-width: 300px;
  }
`;

const Stick = styled.div`
  position: absolute;
  background-color: var(--secondary);
  left: ${(props) => props.right && 0};
  right: ${(props) => !props.right && 0};
  top: 25px;
  height: 1px;
  width: 40px;
  transform: ${(props) => (props.right ? "translateX(-100%)" : "translateX(100%)")};
  ::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: var(--neon);
    border-radius: 50%;
    top: 50%;
    left: ${(props) => props.right && 0};
    right: ${(props) => !props.right && 0};
    transform: ${(props) => (props.right ? "translateX(-100%) translateY(-50%)" : "translateX(100%) translateY(-50%)")};
    box-sizing: border-box;
    transition: 0.25s ease;
  }

  @media (max-width: 768px) {
    width: 20px;
    transform: translateX(-100%);
    left: 0;
    right: auto;
    ::after {
      transform: translateX(-100%) translateY(-50%);
      left: 0;
      right: auto;
    }
  }
`;

const RoadmapCard = ({ date, title, body, complete, right }) => {
  return (
    <Card right={right}>
      <Stick className="stick" right={right} />
      {complete && <Check className="check" />}
      {date && <p className="date">{date}</p>}
      <h1 className="title">{title}</h1>
      <p className="body" dangerouslySetInnerHTML={{ __html: body }}></p>
      <img src={hero} loading="lazy" />
    </Card>
  );
};

export default RoadmapCard;
