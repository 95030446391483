const roadmap = [
  {
    date: null,
    title: "0% Attack!",
    body: "The MetaRats break out of the Sewers and take over the metaverse.",
    complete: false,
  },
  {
    date: null,
    title: "20% VIP Cigar Club",
    body: "The MetaRats Cigar Club opens exclusively for MetaRat Hodlers on discord.",
    complete: false,
  },
  {
    date: null,
    title: "40% The Cheese Vault",
    body: "The Cheese Vault will be used to finance the growth, success and dominance of the Big Cheese Empire.",
    complete: false,
  },
  {
    date: null,
    title: "60% The Cheese Factory",
    body: "The Cheese Factory is a Launchpad for new and upcoming projects in the space, it is a way to elevate the NFT space while giving back to our beloved community.<br /><br /> Members of the Cigar Club will vote on which project the MetaRats will support and will be handsomely rewarded for their participation...",
    complete: false,
  },
  {
    date: null,
    title: "80% The Cheese Shop",
    body: "Faucet Failure!<br /><br /> The MetaRats Cheese Shop will offer exclusive MetaRats merch for the community… Just don't drown on all the drip…<br /><br />MetaRats Cheese? Maybe...",
    complete: false,
  },
  {
    date: null,
    title: "100% MetaRats Community Leaders Election",
    body: "A project is ONLY as good as its community YOU will vote for YOUR community leaders.<br /><br /> Community leaders will organize events, charities, giveaways and act as ambassadors of The MetaRats.",
    complete: false,
  },
];

export default roadmap;
