import {Box, CircularProgress} from "@mui/material";
import {Flex} from "../Generic";
import styled from "styled-components";


const SectionContainer = styled.div`
  letter-spacing: 0px;
  background: linear-gradient(130deg, var(--yellow), var(--yellow-alt));
  box-shadow: -5px 10px 10px rgb(6, 35, 51, 0.2);
  .textSection {
    width: 70%;
    max-width: 500px;
    align-items: center;
    margin-left: 14%;
    h1 {
      font-weight: 500;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 26px;
      margin-top: 0;
    }
    img {
      max-width: 100%;
    }
    button {
      width: 200px;
      height: 50px;
      border: 1px solid var(--dark-blue);
      position: relative;
      color: var(--dark-blue);
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: var(--font-family-heading);
      font-size: 20px;
      font-weight: 400;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: transparent;
      margin-bottom: 20px;
      margin-top: -20px;
      ::after {
        content: "Reload Collection";
        transition: opacity 0.25s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        color: var(--yellow-alt);
        background: linear-gradient(130deg, var(--light-blue), var(--dark-blue));
        opacity: 0;
        pointer-events: none;
      }
      :hover {
        ::after {
          opacity: 1;
        }
      }
`;

export const ReloadCollection = (props) => {
    if(props.state.loading) {
        return(
            <Box style={{justifyContent: 'center', display: 'flex', marginTop: 30, marginBottom: 100}}>
                <SectionContainer style={{width: "27vw"}} flexDirection="row" justifyContent="center" alignItems="center">
                    <Flex flexDirection="column" className="textSection">
                        <h1>Oops.</h1>
                        <p>You do not have any rats!</p>
                        <CircularProgress color="success" style={{marginBottom: 30}} />
                    </Flex>
                </SectionContainer>
            </Box>
        )
    }
    return(
        <Box style={{justifyContent: 'center', display: 'flex', marginTop: 30, marginBottom: 100}}>
            <SectionContainer style={{width: "27vw"}} flexDirection="row" justifyContent="center" alignItems="center">
                <Flex flexDirection="column" className="textSection">
                    <h1>Oops.</h1>
                    <p>You do not have any rats!</p>
                    <button onClick={props.handleReload}>Reload Collection</button>
                </Flex>
            </SectionContainer>
        </Box>
    )
}