import styled from "styled-components";
import hero from "../../assets/HeroImage3-min.png";
import logo from "../../assets/MetaRatsLogo-min.png";
import cheesewheel from '../../assets/CheeseWheel.png'
import { Flex } from "../Generic";

const Container = styled.div`
  position: relative;

  .dimmer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0f0f0f;
    opacity: 0.65;
    z-index: 1;
  }
  #cards {
    gap: 20px;
  }
  #hero-content {
    position: relative;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > img {
      width: 700px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1200px) {
    #cards {
      flex-direction: column;
      gap: 20px;
    }

    #hero-content {
      padding-bottom: 50px;
      & > img {
        width: 200px;
        margin-bottom: 10px;
        margin-top: 140px;
      }
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #021922;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);

  padding: 30px 25px 60px;
  img {
    width: 200px;
    transform: translateY(30px);
    transition: 0.25s ease-out;
  }

  .rat {
    left: 20px;
    margin-bottom: 20px;
    image-rendering: -webkit-optimize-contrast;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    max-width: 270px;
    color: white;
    opacity: 0;
    transition: 0.25s ease-out;
  }
  button {
    width: 85%;
    height: 50px;
    background-color: transparent;
    border: 1px solid var(--yellow-alt);
    color: var(--yellow-alt);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: 0.25s ease-out;
    cursor: pointer;
    transform: translateY(-20px);
    :hover {
      color: var(--dark-blue);
      background-color: var(--yellow-alt);
    }
  }

  :hover {
    img,
    button {
      transform: none;
    }
    p {
      opacity: 1;
    }
  }

  @media (max-width: 1200px) {
    img,
    button {
      transform: none;
    }
    p {
      opacity: 1;
    }
  }
`;

const HeroImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const Hero = (props) => {
  return (
    <Container>
      <Flex id="hero-content">
        <img src={logo} alt="MetaRats" />
        <Flex id="cards">
          <Card>
              <img src={cheesewheel} alt="Rat2" className="rat" />
            <p>The $SCRT is out... the big cheese mafia takes over the metaverse</p>
              <button onClick={() => window.open('https://stashh.io/collection/secret1pfdgr7cdrkxhfpz7anzzr67gyrchyxz4q3vtsk', '_blank').focus()}>Marketplace</button>
          </Card>
        </Flex>
      </Flex>
      <div className="dimmer" />
      <HeroImg loading="eager" src={hero} />
    </Container>
  );
};

export default Hero;
