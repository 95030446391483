import styled from "styled-components";
import { Flex, IconButton } from "../Generic";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTelegramPlane, faTwitter} from "@fortawesome/free-brands-svg-icons";

const FooterContainer = styled.div`
  height: 100px;
  background: transparent;
  padding: 30px 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  h1 {
    color: var(--primary);
    font-family: var(--font-family-body);
    font-weight: 300;
    letter-spacing: 4px;
    font-size: 16px;
  }

  @media (max-width: 1200px) {
    padding: 30px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Flex justifyContent="space-between" alignItems="center">
        <h1>METARATS</h1>
        <Flex id="social-icons">
          <IconButton href="https://discord.gg/sRn6Yb594K" target="_blank" rel="noopener noreferrer" fill="var(--yellow)">
            <FontAwesomeIcon size="lg" icon={faDiscord} style={{color: "#fff"}} />
          </IconButton>
          <IconButton href="https://t.me/metarats" target="_blank" rel="noopener noreferrer" fill="var(--yellow)">
            <FontAwesomeIcon size="lg" icon={faTelegramPlane} style={{color: "#fff"}} />
          </IconButton>
          <IconButton href="https://twitter.com/MetaRatsNFT" target="_blank" rel="noopener noreferrer" fill="var(--yellow)">
            <FontAwesomeIcon size="lg" icon={faTwitter} style={{color: "#fff"}} />
          </IconButton>
        </Flex>
      </Flex>
    </FooterContainer>
  );
};

export default Footer;
