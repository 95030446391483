import styled from "styled-components";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageList from "@mui/material/ImageList";
import {Box} from "@mui/material";
import {ReloadCollection} from "../ReloadCollection";

const SectionContainer = styled.div`
  letter-spacing: 0px;
  background: linear-gradient(130deg, var(--yellow), var(--yellow-alt));
  box-shadow: -5px 10px 10px rgb(6, 35, 51, 0.2);
  .textSection {
    width: 70%;
    max-width: 500px;
    align-items: center;
    margin-left: 14%;
    h1 {
      font-weight: 500;
      margin-bottom: 0px;
    }
    p {
      font-weight: 500;
      font-size: 26px;
      margin-top: 0;
    }
    img {
      max-width: 100%;
    }
    button {
      width: 200px;
      height: 50px;
      border: 1px solid var(--dark-blue);
      position: relative;
      color: var(--dark-blue);
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: var(--font-family-heading);
      font-size: 20px;
      font-weight: 400;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: transparent;
      margin-bottom: 20px;
      margin-top: -20px;
      ::after {
        content: "Reload Collection";
        transition: opacity 0.25s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        color: var(--yellow-alt);
        background: linear-gradient(130deg, var(--light-blue), var(--dark-blue));
        opacity: 0;
        pointer-events: none;
      }
      :hover {
        ::after {
          opacity: 1;
        }
      }
`;



const CollectionItems = (props) => {

    const enableLoading = (item) => {
        setTimeout(() => {
            props.setRatID(item)
            props.setShowLoader(false);
        }, 2000);
    }

    const handleReload = async () => {
        props.dispatch({type: 'set-loading', payload: true})
        props.dispatch({type: 'set-tokens', payload: (await props.grabRats(props.dispatch))})
    }

    if(props.state.tokens.length > 0){
        return (
            <Box style={{justifyContent: 'center', display: 'flex', marginBottom: 100, marginTop: 15}}>
                <SectionContainer style={{width: "50vw", height: "25vw"}}>
                    <ImageList cols={4} gap={8} style={{pt: 20, pb: 20, paddingLeft: 20, paddingRight: 20, maxHeight: "24vw"}}>
                        {props.state.tokens.map((item) => (
                            <ImageListItem style={{cursor: 'pointer' }}
                                           onClick={() => {
                                                    props.setShowLoader(true)
                                                    enableLoading(item)
                                               }}
                                           key={item}
                            >
                               <img
                                   src={'/collection/' + item + '.png'}
                                   alt={item}
                                />
                                <ImageListItemBar
                                    sx={{
                                        background:
                                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                        textAlign: 'left'
                                    }}
                                    position="top"
                                />
                                <ImageListItemBar align="center" title={"MetaRat #" + item} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </SectionContainer>
            </Box>
        );
    }
    return (
        <ReloadCollection state={props.state} handleReload={handleReload} />
    )
};

export default CollectionItems;
