import {Flex, Spacer} from "../Generic";
import styled from "styled-components";

const Container = styled.div`
  transform: translateZ(0);
  transition: 0.2s ease-out;
  background: linear-gradient(130deg, var(--yellow), var(--yellow-alt));
  box-shadow: -5px 10px 10px rgb(6, 35, 51, 0.2);
  padding: 20px 30px;
  border-top: none;
  width: 100%;
  :hover {
    transform: translateZ(60px);
  }
  img {
    border: 50%;
    width: 246px;
    height: 246px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    background-color: var(--yellow-alt);
  }

  p {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    margin-bottom: 20px;
  }

  h1,
  h2 {
    font-weight: 500;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 400;
    margin: 0;
  }
`;
const Profile = ({ image, name, title, description, link}) => {
  return (
    <Container>
      <Flex flexDirection="column" alignItems="center" style={{cursor: 'pointer'}} onClick={()=> window.open(link, "_blank")}>
        <img src={image} alt={name} loading="lazy" />
        <h1>{name}</h1>
        <h2>{title}</h2>
          <Spacer height="10px" />
        {/*<p>{description}</p>*/}
      </Flex>
    </Container>
  );
};

export default Profile;
