export const initialState = {
    loading: false,
    isConnected: false,
    openAlert: false,
    alertMessage: '',
    alertType: 'info',
    tokens: []
};

export function reducer(state, action) {
    switch (action.type) {
        case 'set-loading':
            return {
                ...state,
                loading: action.payload
            };
        case 'set-connected':
            return {
                ...state,
                isConnected: action.payload
            };
        case 'set-alert-type':
            return {
                ...state,
                alertType: action.payload
            };
        case 'set-open-alert':
            return {
                ...state,
                openAlert: action.payload
            };
        case 'set-alert-message':
            return {
                ...state,
                alertMessage: action.payload
            };
        case 'set-tokens':
            return {
                ...state,
                tokens: action.payload
            };
        default:
            throw new Error();
    }
}