import styled from "styled-components";
import roadmap from "../../roadmap";
import RoadmapCard from "./Card";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 40px 0;
  ::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: var(--secondary);
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    padding-left: 25px;
    ::before {
      left: 0;
    }
  }
`;

const Orb = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--neon);
  border-radius: 50%;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    left: 0;
  }
`;
const Roadmap = () => {
  return (
    <Container>
      <Orb top="0" />
      {roadmap.map((section, i) => (
        <RoadmapCard key={`roadmap-${i}`} {...section} right={i % 2 === 0} />
      ))}
      <Orb bottom="0" />
    </Container>
  );
};

export default Roadmap;
