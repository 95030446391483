import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./hamburgers.css";
import App from "./App";
import Collection from './Collection'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import CigarClub from "./CigarClub";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="/*" element={<App />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/cigarclub" element={<CigarClub />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
