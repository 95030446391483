import styled from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import pipeLeft from "./assets/pipe-left-min.png";
import pipeRight from "./assets/pipe-right-min.png";
import { SpriteAnimator } from "react-sprite-animator";
import spritesheetalt from "./assets/spritesheet-alt.png";
import {useEffect, useReducer, useState} from "react";
import {initialState, reducer} from "./keplr/reducer";
import {connectKeplr} from "./keplr/keplr_func";
import LinkDiscord from "./components/LinkDiscord";

const Page = styled.div`
  position: relative;
  main {
    background-image: url(${pipeLeft}), url(${pipeRight});
    background-size: contain;
    background-repeat: repeat-y;
    background-position-x: left, right;
    & > div {
      max-width: 1100px;
      margin: 0 auto;
      .glare-wrapper {
        pointer-events: none;
      }
      .parallax {
        background: linear-gradient(130deg, var(--yellow), var(--yellow-alt));
        box-shadow: -5px 10px 10px rgb(6, 35, 51, 0.2);
        padding: 20px 30px;
        transform: translateZ(500px);
        transform-style: preserve-3d;
      }
      @media (max-width: 1200px) {
        padding: 50px 20px 0;
        .parallax {
          padding: 25px 30px;
        }

        #profiles {
          flex-direction: column;
          align-items: center;
          gap: 30px;
        }
      }
    }
    @media (max-width: 1200px) {
      background: none;
    }
  }
`;

const CigarClub = () => {
  const [showLoader, setShowLoader] = useState(true);
    const [state, dispatch] = useReducer(reducer, initialState);
  useEffect( () => {
      async function connect() {
          await connectKeplr(dispatch)
      }
      connect().then(() => console.log('connected'))
    setTimeout(() => {
      setShowLoader(false);
      setTimeout(() => {
        document.body.classList.remove("lock-scroll");
      }, 700);
    }, 2000);
  }, []);
  return (
    <Page>
      <div
        style={{
          position: "fixed",
          zIndex: 200,
          top: 0,
          left: 0,
          background: "linear-gradient(var(--light-blue) -100%, var(--dark-blue) 40%)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: showLoader ? 1 : 0,
          transition: "opacity 0.5s ease",
          transitionDelay: "0.7s",
          pointerEvents: !showLoader && "none",
        }}
      >
        <div style={{ transform: showLoader ? "scale(1.2)" : "scale(1.1)", opacity: showLoader ? 1 : 0, transition: "0.35s ease-out" }}>
          <SpriteAnimator sprite={spritesheetalt} width={100} height={100} fps={30} />
        </div>
      </div>
      <Header />
        <LinkDiscord dispatch={dispatch} state={state} />
      <Footer />
    </Page>
  );
};

export default CigarClub;
