import { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/logo-min.png";
import { Flex, IconButton } from "../Generic";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter, faTelegramPlane } from '@fortawesome/free-brands-svg-icons'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 100px;
  z-index: 100;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  .hamburger {
    display: none;
    z-index: 1;
  }
  img,
  #social-icons {
    z-index: 1;
  }

  #blurred {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(130deg,var(--light-blue),var(--dark-blue));
    backdrop-filter: blur(10px);
    z-index: 0;
  }
  @media (max-width: 1200px) {
    padding: 5px 0 5px 20px;
    img {
      width: 50px;
    }
    #social-icons {
      display: none;
    }
    .hamburger {
      display: block;
    }
  }
`;
const MenuItems = styled.div`
  display: flex;
  gap: 45px;
  z-index: 1;
  @media (max-width: 1200px) {
    flex-direction: column;
    position: fixed;
    background: linear-gradient(130deg, var(--light-blue), var(--dark-blue));
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 60%;
    max-width: 400px;
    right: 0;
    top: 0;
    height: 100%;
    padding: 80px 0 0 50px;
    transform: ${(props) => (props.open ? "translateX(0%)" : "translateX(100%)")};
    transition: transform 0.25s ease;
  }
`;
const MenuItem = styled.a`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: white;
  transition: 0.25s ease-out;
  letter-spacing: 1px;
  position: relative;
  ::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background-color: var(--neon);
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transition: 0.4s ease-out;
    transform-origin: left;
  }
  :hover {
    color: var(--neon);
    ::after {
      transform: scaleX(1);
    }
  }
  @media (max-width: 1200px) {
    ::after {
      content: none;
    }
    :hover {
      color: white;
    }
    :active {
      color: var(--neon);
    }
  }
`;

const Header = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    open ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "visible");
  }, [open]);
  return (
    <HeaderContainer>
      <img width="60" src={logo} alt="MetaRats" />
      <MenuItems open={open}>
          <MenuItem href="/">Home</MenuItem>
          <MenuItem href="/collection">My Collection</MenuItem>
          <MenuItem href="/cigarclub">Cigar Club</MenuItem>
          <MenuItem href="https://docs.google.com/forms/d/1EaooP3Ihf-ejXHB6wOd3oGsM8-V8HhAi5nmuKKtR5nI" target="_blank">KickStart</MenuItem>

      </MenuItems>
      <Flex id="social-icons">
        <IconButton href="https://discord.gg/sRn6Yb594K" target="_blank" rel="noopener noreferrer" fill="var(--neon)">
            <FontAwesomeIcon size="lg" icon={faDiscord} style={{color: "#fff"}} />
        </IconButton>
          <IconButton href="https://t.me/metarats" target="_blank" rel="noopener noreferrer" fill="var(--neon)">
              <FontAwesomeIcon size="lg" icon={faTelegramPlane} style={{color: "#fff"}} />
          </IconButton>
        <IconButton href="https://twitter.com/MetaRatsNFT" target="_blank" rel="noopener noreferrer" fill="var(--neon)">
            <FontAwesomeIcon size="lg" icon={faTwitter} style={{color: "#fff"}} />
        </IconButton>
      </Flex>
      <button className={`hamburger hamburger--emphatic ${open && "is-active"}`} type="button" onClick={() => setOpen(!open)}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div id="blurred" />
    </HeaderContainer>
  );
};

export default Header;
