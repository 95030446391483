import styled from "styled-components";
const IconButton = styled.a`
  width: ${(props) => props.width || "20px"};
  svg {
    width: 100%;
    height: auto;
  }
  path {
    display: inline-block;
    transition: 0.25s ease;
  }
  :hover {
    path {
      fill: ${(props) => props.fill};
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "flex-start"};
  gap: 15px;
`;

const Spacer = styled.div`
  height: ${(props) => props.height};
`;
export { IconButton, Flex, Spacer };
